<template>
    <div>        
    </div>
</template>
<script type="text/javascript">
export default {
  data () {
    return {
      ready:true,      
    }
  },
  components: {
  },
  beforeMount() {
    var the = this;
    if(this.$route.params.q != undefined){
        var tmp = atob(this.$route.params.q);
        tmp = tmp.split(":");
        var stripe = window.Stripe(tmp[0]);
        stripe.handleCardPayment(tmp[1])
        .then(function() {
          the.$router.push({path:"/login"});
        });
    }
  },
  mounted() {      
  },
  computed:{    
  },
  methods: {
  },
  watch:{
  }
}
</script>
<style media="screen">
</style>